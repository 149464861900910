<template>
	<v-col>
		<v-layout justify-end
			><v-btn color="primary" @click="backToSearch()" dark
				>返回 Back</v-btn
			></v-layout
		>
		<v-col>
			<v-layout justify-center class="page-title"
				><strong>{{ labelsContent.submissionTitles[action] }}</strong></v-layout
			>
			<v-layout justify-center>
				<v-layout justify-center>
					<v-form ref="form" class="sub-form" :disabled="action === 'view'">
						<v-card>
							<v-card-text
								><strong>地點編號 Location ID:</strong><br />
								{{ location_data.location_id }}</v-card-text
							>
							<v-card-text
								><strong>地址 Address:</strong><br />
								<span
									v-html="
										concatAddr(
											location_data.address_tc,
											location_data.address_en,
										)
									"
								></span>
							</v-card-text>
							<v-card-text
								><strong>{{locationTypeLabel}}:</strong>
								<br />{{ location_data.location_no }}</v-card-text
							>
						</v-card>
						<v-divider black style="margin: 20px 0px 40px 0px;"></v-divider>
						<v-menu
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							max-width="290px"
							min-width="auto"
							v-model="menu2"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									:disabled="action !== 'create'"
									v-model="computedDateFormatted"
									label="檢查日期 Examination date"
									hint="DD/MM/YYYY"
									persistent-hint
									prepend-icon="mdi-calendar"
									readonly
									required
									v-bind="attrs"
									v-on="on"
									:rules="examDateRule"
									style="margin-bottom: 30px"
								></v-text-field>
							</template>
							<v-date-picker
								v-on:change="setTerms"
								:disabled="action !== 'create'"
								v-model="examDate"
								no-title
								@input="menu2 = false"
								:max="getCurrentDate()"
								:show-current="false"
							></v-date-picker>
						</v-menu>
						<v-select
							label="機器類型 Machine Type"
							item-text="text"
							item-value="value"
							:items="machineTypes"
							v-model="selectedMachine"
							outlined
							v-on:change="machineTypeChange"
							return-object
						>
						</v-select>
						<v-col>
							<v-card
								v-for="(item, index) in selectedMachine.components"
								:key="item.label"
								elevation="0"
								class="form-card"
							>
								<v-card-text class="form-fld-lbl">{{ item.label }}</v-card-text>
								<v-img v-if="checkImg(images[index])" :src="image_urls[index]" :contain="true" max-height="300px" height="300px"></v-img>
								<v-layout>
									<v-col>
										<v-file-input
											@change="previewImage(images[index], index)"
											v-model="images[index]"
											:rules="item.optional? optionalPhoto(labelsContent.UPLOAD_FILE_SIZE, image_urls, images): requiredPhoto(item.label,labelsContent.UPLOAD_FILE_SIZE)"
											placeholder="瀏覽檔案 Browse File"
											:prepend-icon="images[index] ? null : 'mdi-camera'"
											accept="image/png, image/jpeg, image/jpg, image/heic"
											outlined
											dense
										></v-file-input>
									</v-col>
									<v-col v-if="images[index]">
										<v-btn
											color="red darken-4"
											@click="removeImage(index)"
											block
											dark
											>刪除 Remove</v-btn
										>
									</v-col>
								</v-layout>
							</v-card>
						</v-col>
						<v-col>
							<v-card elevation="0" class="form-card">
								<v-card-text class="form-fld-lbl">備註 Remarks</v-card-text>
								<v-textarea v-model="remarks" outlined></v-textarea>
							</v-card>
						</v-col>
						<v-checkbox
							v-if="action === 'create'"
							:label="tcContent"
							v-model="agreeTNC"
							:rules="checkTC"
						></v-checkbox>
						<v-btn
							@click="submitForm"
							color="blue"
							v-if="action !== 'view'"
							block
							dark
							>{{ labelsContent.buttonLabels[action] }}</v-btn
						>
					</v-form>
				</v-layout>
			</v-layout>
		</v-col>
	</v-col>
</template>

<script>
// import EnginerService from '../service/engineer-service';
import * as submissionLabel from '../config/submission-details.config';
import { requiredPhoto, optionalPhoto } from '../config/form-rules.config';
// const engineerService = new EnginerService();

import EnginerService from '../service/engineer-service';
const engineerService = new EnginerService();
const heic2any = require('heic2any');
import SubmissionService from '../service/submission-service';
const submissionService = new SubmissionService();
import LoginService from '../service/login-service';
const loginService = new LoginService();
const moment = require('moment-timezone');

export default {
	name: 'SubmissionDetails',
	props: {
		action: {
			type: String,
		},
		id: {
			type: String,
		},
	},
	computed: {
		computedDateFormatted() {
			return this.formatDate(this.examDate);
		},
	},
	data: () => ({
		menu2: false,
		form: null,
		requiredPhoto: requiredPhoto,
		optionalPhoto: optionalPhoto,
		examDate: '',
		examDateRule: [
			(v) => !!v || '檢查日期必須填寫 Examination date is Required',
		],
		selectedMachine: {},
		labelsContent: submissionLabel,
		machineTypes: [],
		location_data: {},
		remarks: null,
		agreeTNC: false,
		images: {},
		// allImages: [], //for swtiching between machine type if necessary
		image_urls: [],
		checkTC: [
			(val) => {
				return val;
			},
		],
		tcContent: '',
		locationTypeLabel: ''
	}),
	methods: {
		isValidFileType(type){
			const validTypes = ['image/heic','image/jpg','image/jpeg','image/png'];
			return validTypes.includes(type);
		},
		checkImg(img){
			if (!img){
				return false;
			}else{
				return this.isValidFileType(img.type);
			}
		},
		setTerms() {
			let tcDateStr = 'YYYY年MM月DD日';
			let enDateStr = 'DD/MM/YYYY';
			if (this.examDate != '') {
				let momentDate = moment(this.examDate, 'YYYY-MM-DD'); //date picker format must be YYYY-MM-DD
				tcDateStr = momentDate.format('YYYY年MM月DD日');
				enDateStr = momentDate.format('DD/MM/YYYY');
			}
			this.tcContent = `本人確認所上載相片為地點編號 ${this.id} 於${tcDateStr}定期檢驗時所拍攝。I confirmed that the photos uploaded is taken during periodic examination on ${enDateStr} at location ID ${this.id}`;
		},
		getCurrentDate() {
			return moment().format();
		},
		formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		backToSearch() {
			this.$router.push({ path: '/search' });
		},
		concatAddr(addr_tc, addr_en) {
			return addr_tc + '<br/>' + addr_en;
		},
		renameImage(image, key_index) {
			return new Promise((resolve, reject) => {
				if (image.type.toLowerCase() == 'image/heic') {
					var blob = image;
					heic2any({
						blob: blob,
						toType: 'image/jpeg',
						quality: 0.7,
					})
						.then((resultBlob) => {
							// const url = resultBlob ? URL.createObjectURL(resultBlob) : '';

							console.log(this.selectedMachine.components[key_index].prefix);
							let newFileName =
								this.selectedMachine.components[key_index].prefix + '.jpg';
							let file = null;
							try {
								file = new File([resultBlob], newFileName, {
									type: 'image/jpeg',
								});
							} catch (fileErr) {
								console.log('fileErr');
								console.log(fileErr);
								var newBlob = new Blob([resultBlob], { type: 'image/jpeg' });
								newBlob['name'] = newFileName;
								// newBlob['lastModifiedDate'] = new Date();
								file = newBlob;
							}
							console.log(file);
							resolve(file);
							// console.log(url);
							// console.log(this.images);
							// this.$nextTick(() => {
							// 	// DOM is now updated
							// 	// `this` is bound to the current instance

							// });
							//this.image_urls[key_index] = url;

							// do trick to update array - https://michaelnthiessen.com/debugging-guide-why-your-component-isnt-updating/
							// this.image_urls.splice(key_index, 1, url);
							// console.log(this.image_urls);
						})
						.catch((x) => {
							console.log('Go to catch block');
							console.log(x);
							reject(x);
						});
				} else {
					// const url = image ? URL.createObjectURL(image) : '';
					// this.image_urls[key_index] = url;
					// console.log(this.image_urls);
					let imgPath = image.name.split('.');
					let ext = imgPath[imgPath.length - 1];

					let newFileName =
						this.selectedMachine.components[key_index].prefix + '.' + ext;
					let file = null;
					try {
						file = new File([image], newFileName, { type: image.type });
					} catch (fileErr) {
						// console.log('fileErr');
						// console.log(fileErr);
						var newBlob = new Blob([image], { type: image.type });
						newBlob['name'] = newFileName;
						// newBlob['lastModifiedDate'] = new Date();
						file = newBlob;
					}
					console.log(file);
					resolve(file);
				}
			});
		},
		previewImage(image, key_index) {
			// console.log(image.size);
			//to prevent error when removing the image			
			if (image) {
				if (this.isValidFileType(image.type)){
					if (image.size < this.labelsContent.UPLOAD_FILE_SIZE) {
						if (image.type.toLowerCase() == 'image/heic') {
							let loader = this.$loading.show({
								// Optional parameters
								container: null,
								canCancel: false,
							});
							var blob = image;
							heic2any({
								blob: blob,
								toType: 'image/jpeg',
								quality: 0.7,
							})
								.then((resultBlob) => {
									const url = resultBlob ? URL.createObjectURL(resultBlob) : '';

									loader.hide();
									// console.log(url);
									// console.log(this.images);
									// this.$nextTick(() => {
									// 	// DOM is now updated
									// 	// `this` is bound to the current instance

									// });
									//this.image_urls[key_index] = url;
									
									// do trick to update array - https://michaelnthiessen.com/debugging-guide-why-your-component-isnt-updating/
									this.image_urls.splice(key_index, 1, url);
									// console.log(this.image_urls);
									this.$forceUpdate();
								})
								.catch((x) => {
									console.log(x);
								});
						} else {
							const url = image ? URL.createObjectURL(image) : '';	
							if (this.image_urls[key_index != null]){
								this.removeImage(key_index);
							}					
							this.image_urls.splice(key_index, 1, url);
							this.$forceUpdate();
							console.log(this.image_urls);
						}
					}
					//do nothing if the file size > 3MB
				}else{
					//not supported file type
					console.log('not support file type!');
				}
			}
		},
		removeImage(key_index) {
			this.images[key_index] = null;
			this.image_urls[key_index] = null;
		},
		promptSnackBar(msg, color, extraProps = {}) {
			this.$emit('snack-bar', {
				color,
				msg,
				extraProps,
			});
		},
		isPhotoUploadValid(){
			//check at least one photo is uploaded
			let isValid = false;
			for (let i = 0; i < this.image_urls.length; i++) {
				if (this.images[i]) {
					isValid = true;
				}
			}
			return isValid;
		},
		async submitForm() {
			const valid = this.$refs.form.validate();			
			console.log('isvalid:' + this.isPhotoUploadValid());

			if (valid && this.isPhotoUploadValid()) {				
				if (loginService.isLogin()) {
					console.log(valid);
					let loader = this.$loading.show({
						// Optional parameters
						container: null,
						canCancel: false,
					});

					//get presigned url
					try {
						// console.log(this.image_urls.length);
						let imgArr = [];
						for (let i = 0; i < this.image_urls.length; i++) {
							if (this.images[i]) {
								// imgArr[i] = this.images[i];
								imgArr[i] = await this.renameImage(this.images[i], i);
							} else {
								imgArr[i] = null;
							}
						}

						console.log(imgArr);
						let presignedUrls = await submissionService.getPresignedUrls(
							imgArr,
						);
						console.log(presignedUrls);

						let photoArr = [];
						//upload to S3
						for (let i = 0; i < imgArr.length; i++) {
							let retry = 0;
							let isUploaded = false;
							if (imgArr[i] != null) {
								while (retry < 2 && !isUploaded) {
									try {
										let result = await submissionService.uploadToS3(
											imgArr[i],
											presignedUrls[i].presigned_url,
										);
										isUploaded = result.statusText == 'OK';
										console.log(isUploaded);
									} catch (uploadErr) {
										console.log(uploadErr);
									}
									retry++;
								}

								//check the upload is success or not
								if (!isUploaded) {
									throw '上載失敗，請重試！Upload failed, please try again!';
								}

								photoArr.push({
									photo_no: i,
									url: presignedUrls[i].bucket_path,
								});
							}
						}

						//submit the submission
						let reqData = {};
						reqData.location_id = this.location_data.location_id;
						reqData.examination_date = this.examDate;
						reqData.machine_type = this.selectedMachine.value;
						reqData.remark = this.remarks;
						reqData.photos = photoArr;

						console.log(reqData);
						try {
							let subRes = await submissionService.createSubmission(
								loginService.getEngineerNo(),
								reqData,
							);
							console.log(subRes);
							if (subRes.data.success) {
								try{
									let refRes = await engineerService.refreshToken()
									console.log(refRes);
								}catch(refErr){
									//refresh error, just leave it
									console.log(refErr);
								}
								this.promptSnackBar('遞交成功! Submit success!', 'success');
							} else {
								throw '遞交失敗，請重試！Submit failed, please try again!';
							}
						} catch (subErr) {
							console.log(subErr);
							throw '遞交失敗，請重試！Submit failed, please try again!';
						}

						loader.hide();
						this.$router.push('/search');
					} catch (err) {
						//error for getting presigned urls
						console.log(err);
						this.promptSnackBar(err, 'error');
						loader.hide();
					}
				} else {
					this.promptSnackBar(
						'登入時效已過，請重新登入！Token expired, please login again!',
						'error',
					);
					this.$router.push({ path: '/login' });
				}
			}
			// 	// call api
			// 	this.promptSnackBar(
			// 		this.field_configs.submit_message.success[this.mode],
			// 		'success',
			// 	);
			// 	this.$router.push('search');
			// } else {
			// 	const extraProps = {
			// 		showRetry: true,
			// 		retryFunction: () => {
			// 			this[this.mode]();
			// 		},
			// 	};
			// 	this.promptSnackBar(
			// 		this.field_configs.submit_message.failed[this.mode],
			// 		'error',
			// 		extraProps,
			// 	);
			// }
		},
		new() {
			// call new api
			console.log('create');
		},
		edit() {
			// call edit api
			console.log('edit');
		},
		machineTypeChange(item) {
			console.log(item.value);

			//clear the image_urls and images
			this.image_urls = new Array(
				this.labelsContent.liftType[0].components.length,
			);
			this.images = {};
		},
	},
	created() {
		console.log(this.action);
		if (this.action == 'create') {
			//get data from location API
			engineerService
				.searchLocation(this.id)
				.then((res) => {
					// this.location_data = res
					// search for sub history

					/*

                            {
                            "success": true,
                            "result": {
                                "id": "string",
                                "location_no": "string",
                                "location_type": 0,
                                "address_en": "string",
                                "address_tc": "string"
                            }
                            }
                        */

					if (res.data.success && res.data.result.type == loginService.getEngineerType()) {
						//prepare the T&C content
						this.setTerms();

						this.location_data = res.data.result;
						

						// default select first machine type, so image upload field will be populated
						if (this.location_data.type == submissionLabel.LOCATION_LIFT_TYPE) {
							this.machineTypes = this.labelsContent.liftType;
							this.selectedMachine = this.labelsContent.liftType[0];
							this.image_urls = new Array(
								this.labelsContent.liftType[0].components.length,
							);
							this.locationTypeLabel = '升降機編號 Lift No.';

							//init the images array for swtiching machine type
							// this.allImages = new Array(this.labelsContent.liftType.length);
							// for (let i = 0; i < this.labelsContent.liftType.length; i++) {
							// 	this.allImages[i] = new Array(
							// 		this.labelsContent.liftType[i].components.length,
							// 	);
							// }
						} else {
							this.machineTypes = this.labelsContent.escalatorType;
							this.selectedMachine = this.labelsContent.escalatorType[0];
							this.image_urls = new Array(
								this.labelsContent.escalatorType[0].components.length,
							);
							this.locationTypeLabel = '扶手電梯編號 Escalator No.';

							//init the images array for swtiching machine type
							// this.allImages = new Array(this.labelsContent.liftType.length);
							// for (let i = 0; i < this.labelsContent.liftType.length; i++) {
							// 	this.allImages[i] = new Array(
							// 		this.labelsContent.liftType[i].components.length,
							// 	);
							// }
						}

						console.log(this.machineTypes);
						console.log(this.selectedMachine);
					} else {

						if (res.data.success && res.data.result.type != loginService.getEngineerType()) {
							this.promptSnackBar(
								"地點與執照類型不相乎。Location does not match with Engineer's license type.",'error'
							);
						} else {
							this.promptSnackBar('地點不存在。Location not found.','error');
						}
						// //redirect to search page if location not found
						// this.promptSnackBar(
						// 	'Invalid Location ID, please search again.',
						// 	'error',
						// );
						this.$router.push('/search');
					}
				})
				.catch(() => {
					//redirect to search page if any error
					this.promptSnackBar(
						'Invalid Location ID, please search again.',
						'error',
					);
					this.$router.push('/search');
				});
		} else {
			submissionService
				.getSubmission(loginService.getEngineerNo(), this.id)
				.then((res) => {
					if (res.data.success) {
						console.log(res.data);
						this.location_data = res.data.result.location;
						this.examDate = res.data.result.examination_date;
						this.remarks = res.data.result.remark;
						if (this.action == 'update') {
							console.log('update submission');
							//get data from get submission API
						} else {
							//view
						}
					} else {
						this.promptSnackBar(
							'Submission not found, please search again.',
							'error',
						);
						//submission not found
						this.$route.push('/search');
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	},
};
</script>

<style lang="scss">
.sub-ttl {
	font-size: x-large;
	padding-bottom: 1rem;
}

.translate-down {
	transform: translateY(2rem);
}

.form-fld-lbl {
	margin-bottom: -1rem;
	transform: translateX(-1.8rem);
}

.form-card {
	margin-bottom: -1rem;
}
</style>
